<template>
  <!-- <TsButton @click="visible = true">Open</TsButton> -->
  <ClientOnly>
    <Teleport to="body">
      <TsModal
        append-class="max-w-md"
        back-drop-class="bg-natural-black/50"
        v-model:visible="savelistStore.modal_savelist_from_visible"
        contentClass="p-0"
      >
        <template #modal-content="{ modal }">
          <form @submit.prevent="modal?.hide()" class="mx-auto">
            <TsRow
              no-gutter
              append-class="justify-between items-center m-0 bg-success/10 px-4"
            >
              <TsColumn append-class="max-w-max">
                <TsRow append-class="m-0 py-5">
                  <!-- <TsIcon
                  class="mt-1.5 mr-1.5 text-[#238826]"
                  name="mdi:tick-circle-outline"
                  size="20"
                />
                <TsTypography
                  append-class="text-[#238826] whitespace-nowrap mt-1"
                  >The item has been added to the list
                </TsTypography> -->
                </TsRow>
              </TsColumn>
              <TsColumn append-class="text-right max-w-max">
                
              </TsColumn>
            </TsRow>

            <TsMedia append-class="items-center m-0 bg-success/10 px-4 py-3">
              <TsMediaStart>
                <NuxtImg
                  v-if="
                    savelistStore.current_active_product &&
                    savelistStore.current_active_product.image
                  "
                  :src="savelistStore.current_active_product?.image"
                  class="bg-white rounded-sm border-[#DEEFFF] border p-1"
                  width="65"
                  alt="save-list-product"
                />
              </TsMediaStart>
              <TsMediaContent>
                <TsTypography append-class="text-idle-black mt-2 " as="p">
                  <span
                    ><strong>{{
                      computeBoldFirstWord(
                        savelistStore?.current_active_product?.name ?? ""
                      )[0]
                    }}</strong>
                    {{
                      computeBoldFirstWord(
                        savelistStore?.current_active_product?.name ?? ""
                      )[1]
                    }}</span
                  >
                </TsTypography>
              </TsMediaContent>
              <TsMediaEnd append-class="mb-auto">
                <TsIcon
                  name="akar-icons:cross"
                  size="20"
                  class="text-idle-black cursor-pointer"
                  @click="modal.hide()"
                />
              </TsMediaEnd>
            </TsMedia>

            <div
              :class="
                savelistStore.user_savelists.length &&
                'max-h-48 mt-1 overflow-y-scroll'
              "
            >
              <div
                v-if="savelistStore.user_savelists_state.status === 'success'"
              >
                <div
                  class="px-4"
                  v-for="(savelist, index) in savelistStore.user_savelists"
                  :key="index"
                >
                  <TsRow append-class="justify-between m-0">
                    <TsColumn append-class="mt-2">
                      <TsRow append-class="m-0">
                        <TsTypography append-class="text-idle-black">{{
                          savelist.name
                        }}</TsTypography>
                        <!-- <TsTag
                        v-if="index < 1"
                        size="xs"
                        variant="info"
                        append-class="ml-3 max-w-max py-0.5 text-primary font-semibold"
                        rounded
                      >
                        <Icon name="bi:pin-angle" size="16" />
                        Default List
                      </TsTag> -->
                      </TsRow>
                    </TsColumn>
                    <TsColumn append-class="text-right">
                      <TsButton
                        :icon="
                          computeIcon(savelist.id)
                            ? 'solar:heart-bold'
                            : 'solar:heart-linear'
                        "
                        :icon-class="
                          computeIcon(savelist.id)
                            ? 'size-4 text-red-600'
                            : 'size-4'
                        "
                        variant="secondary"
                        size="xs"
                        rounded
                        @click.stop="
                          handleHeartToggle(
                            computeIcon(savelist.id),
                            savelist.id
                          )
                        "
                        :is-loading="
                          savelistStore.user_savelists_state.status !==
                          'success'
                        "
                      />
                    </TsColumn>
                  </TsRow>
                  <TsDivider append-class="mx-2 my-0" />
                </div>
              </div>
              <div
                v-if="savelistStore.user_savelists_state.status === 'loading'"
              >
                <div
                  class="px-4"
                  v-for="(savelist, index) in savelistStore.user_savelists"
                  :key="index"
                >
                  <TsRow append-class="justify-between m-0">
                    <TsColumn append-class="mt-2">
                      <TsRow append-class="m-0">
                        <TsTypography append-class="text-idle-black">{{
                          savelist.name
                        }}</TsTypography>
                        <!-- <TsTag
                        v-if="index < 1"
                        size="xs"
                        variant="info"
                        append-class="ml-3 max-w-max py-0.5 text-primary font-semibold"
                        rounded
                      >
                        <Icon name="bi:pin-angle" size="16" />
                        Default List
                      </TsTag> -->
                      </TsRow>
                    </TsColumn>
                    <TsColumn append-class="text-right">
                      <TsButton
                        :icon="
                          computeIcon(savelist.id)
                            ? 'solar:heart-bold'
                            : 'solar:heart-linear'
                        "
                        :icon-class="
                          computeIcon(savelist.id)
                            ? 'size-4 text-red-600'
                            : 'size-4'
                        "
                        variant="secondary"
                        size="xs"
                        rounded
                        @click.stop="
                          handleHeartToggle(
                            computeIcon(savelist.id),
                            savelist.id
                          )
                        "
                        :is-loading="loadingState[savelist.id] || false"
                      />
                    </TsColumn>
                  </TsRow>
                  <TsDivider append-class="mx-2 my-0" />
                </div>
              </div>
            </div>
            <div v-if="savelistStore.user_savelists_state.status === 'failed'">
              <TsNoSavelistFound></TsNoSavelistFound>
              <!-- {{ savelistStore.user_savelists_state.message }} -->
            </div>

            <TsRow append-class="m-0">
              <TsButton
                append-class="border-none bg-inherit hover:bg-white text-primary focus:ring-0 "
                label="Add new list"
                icon="material-symbols:add"
                v-if="!showInput"
                @click="showInput = true"
              />
            </TsRow>

            <TsLabel
              v-if="nameAlreadyTakenCheck"
              label="A savelist with that name already exists!"
              variant="danger"
              class="mx-3 my-1"
            ></TsLabel>
            <TsRow class="mt-3 px-4 m-0" v-if="showInput">
              <TsInput
                v-model="savelistNameModel"
                :variant="nameAlreadyTakenCheck ? 'danger' : 'default'"
                appendClass="pr-1 mb-4"
                placeholder="Create new list"
              >
                <template #right class>
                  <TsButton
                    size="sm"
                    :label="
                      savelistStore.user_savelists_modal_state.status ===
                      'loading'
                        ? 'Wait'
                        : 'Create'
                    "
                    :disabled="validateSavelistName()"
                    @click="handleCreateSavelist"
                    :is-loading="
                      savelistStore.user_savelists_modal_state.status ===
                      'loading'
                    "
                  />
                </template>
              </TsInput>
            </TsRow>

            <div class="mx-4 mb-6">
              <TsRow no-gutter append-class="justify-between space-x-2">
                <TsColumn>
                  <TsButton
                    block
                    size="sm"
                    append-class="whitespace-nowrap"
                    outlined
                    type="submit"
                    label="View Savelists"
                    @click="
                      navigateTo({
                        path: localePath('/account/save-list'),
                      })
                    "
                  />
                </TsColumn>
                <TsColumn append-class="text-right">
                  <TsButton
                    block
                    size="sm"
                    append-class="whitespace-nowrap"
                    type="submit"
                    label="Continue Shopping"
                    :icon="!isMobile && 'mingcute:right-line'"
                  />
                </TsColumn>
              </TsRow>
            </div>
          </form>
        </template>
      </TsModal>
    </Teleport>
  </ClientOnly>
</template>

<script setup lang="ts">
import TsModal from "../molecules/TsModal.vue";

const savelistStore = useSavelistStore();
const savelistNameModel = ref<string>("");

const { isMobile } = useDevice();
const showInput = ref(false);
const itemAddedSuccess = ref<boolean>(false);
const localePath = useLocalePath();
// Track loading state for each savelist item
const loadingState = ref<Record<string, boolean>>({});

const computeBoldFirstWord = (productName: string) => {
  if (!productName) return ["", ""]; // Return empty strings if there's no product name

  // Split the string into words
  const words = productName.split(" ");

  // Return the first word and the rest of the string
  return [words[0], words.slice(1).join(" ")];
};

const handleHeartToggle = async (isSaved: boolean, savelist_id: string) => {
  // Set the loading state for the specific savelist item
  loadingState.value[savelist_id] = true;

  if (isSaved) {
    await savelistStore.deleteItemFromSavelist(
      savelist_id,
      savelistStore.current_active_product?.code as string
    );
  } else {
    await savelistStore.addItemToSavelist(
      savelist_id,
      savelistStore.current_active_product?.code as string,
      "1"
    );
    itemAddedSuccess.value = true;
  }

  // Reset the loading state after the operation
  loadingState.value[savelist_id] = false;
};

const nameAlreadyTakenCheck = ref<boolean>(false);

const validateSavelistName = () => {
  if (savelistNameModel.value.length <= 0) {
    nameAlreadyTakenCheck.value = false;

    return true;
  } else if (
    savelistStore.user_savelists.some(
      (saveList: any) => saveList.name === savelistNameModel.value
    )
  ) {
    nameAlreadyTakenCheck.value = true;
    return true;
  }
  nameAlreadyTakenCheck.value = false;

  return false;
};

const handleCreateSavelist = async () => {
  await savelistStore.createNewList(savelistNameModel.value);
  if (savelistStore.user_savelists_state.status === "success") {
    savelistNameModel.value = "";
  }
};

const computeIcon = (savelist_id: string) => {
  const savelistStore = useSavelistStore();
  const currentProductCode = savelistStore.current_active_product?.code;

  if (!currentProductCode) return false;

  return savelistStore.consolidated_product_code_list.some(
    (item: any) =>
      item.product_code === currentProductCode && item.id === savelist_id
  );
};

const props = withDefaults(defineProps(), {
  image: "/images/dewalt-drill.png",
});

defineOptions({
  inheritAttrs: false,
});
</script>
